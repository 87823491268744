<template>
    <div
            style="position: relative;background: rgb(255, 255, 255);padding: 20px;border-radius: 4px;"
    >
        <div class="line" style="position:relative;margin-bottom: 20px;">
            <a-button-group style="margin: 16px 20px -1px 0px;">
                <a-button
                        @click="tabFn(0)"
                        :class="tabIndex == 0 ? 'ant-btn-primary' : ''"
                        style="border-radius: 0px;"
                >{{ '全部' }}
                </a-button
                >
                <a-button
                        @click="tabFn(1)"
                        :class="tabIndex == 1 ? 'ant-btn-primary' : ''"
                >{{ '系统消息 (5)' }}
                </a-button
                >
                <a-button
                        @click="tabFn(2)"
                        :class="tabIndex == 2 ? 'ant-btn-primary' : ''"
                >{{ '客户消息' }}
                </a-button
                >
                <a-button
                        @click="tabFn(3)"
                        :class="tabIndex == 3 ? 'ant-btn-primary' : ''"
                        style="border-radius: 0px;"
                >{{ '其他通知' }}
                </a-button
                >
            </a-button-group>
            <div style="position: absolute;bottom: 10px;right: 0px;">
                <a-button
                        type="primary"
                        style="margin-right: 10px;font-size: 13px;"
                        size="small"
                        icon="flag"
                        ghost
                >全部标记未已读
                </a-button
                >
                <a-button
                        type="primary"
                        icon="close-circle"
                        size="small"
                        style="font-size: 13px;"
                        ghost
                >全部删除
                </a-button
                >
            </div>
        </div>
        <!-- <a-timeline>
                <span v-for="(item,i) in listData" :key="i">
                    <a-timeline-item :color="primary" style="padding-bottom: 0px;">
                        <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" /> <div class="flexBox"><div>{{item.time}} </div>  <a-button icon="close-circle" type="primary" size="small" ghost @click="delDayFn(i)">删除</a-button></div>
                    </a-timeline-item>
                    <a-timeline-item  v-for="(list,j) in item.arr" :key="j">
                        <div class="flexBox">
                            <div v-for="(items,k) in list" class="ContentBox" style="position:relative;">
                                <div :class="bool?'border-left-bg-cx bgGlob':'border-left-bg-cx default-color'"></div>
                                <div :class="bool?'title':'drak'">{{items.message2.length>fontNum?items.message2.substring(0,fontNum)+"...":items.message2}}</div>
                                <span :class="bool?'':'drak'">2019-08-20 17:53:54</span>
                            </div>
                        </div>
                    </a-timeline-item>
                </span>
            </a-timeline> -->
        <div :style="{ height: height, 'overflow-y': 'auto' }">
            <a-timeline style="margin: 10px;">
                <!--               :color="primary"-->
                <a-timeline-item
                        v-for="(item, i) in listData2"
                        :key="i"
                        :class="
            item.message2
              ? 'posTop ant-menu-item-selected'
              : 'ant-menu-item-selected'
          "
                >
                    <div v-if="item.message2" class="box">
                        <div
                                v-for="(items, k) in item.message2"
                                :key="k"
                                class="ContentBox "
                                @click="clickFn(k)"
                                style="position:relative;"
                        >
                            <div
                                    :class="
                  bool
                    ? 'border-left-bg-cx bgGlob'
                    : 'border-left-bg-cx default-color'
                "
                            ></div>
                            <div :class="bool ? 'title' : 'drak'">
                                {{
                                items.length > fontNum
                                ? items.substring(0, fontNum) + '...'
                                : items
                                }}
                            </div>
                            <span :class="bool ? '' : 'drak'">2019-08-20 17:53:54</span>
                        </div>
                    </div>
                    <a-icon
                            slot="dot"
                            type="clock-circle-o"
                            style="font-size: 16px;color:#1890ff"
                            v-else
                    />
                    <div class="flexBox" v-show="!item.message2" style="color: #666666;">
                        <div>{{ item.time }}</div>
                        <a-button
                                icon="close-circle"
                                style="font-size: 13px;"
                                type="primary"
                                size="small"
                                ghost
                                @click="delDayFn(i)"
                        >删除
                        </a-button
                        >
                    </div>
                </a-timeline-item>
                <!--                <a-timeline-item color="#ffffff"></a-timeline-item>-->
            </a-timeline>
        </div>
        <!-- 分页 -->
        <a-pagination
                showQuickJumper
                :defaultCurrent="2"
                :total="500"
                @change="onChange"
                style="margin-top:16px;"
        />
    </div>
</template>
<script>
    export default {
        name: 'newsList',
        data() {
            return {
                fontNum: 60, //多少个字符开始截取
                tabIndex: 0,
                bool: false,
                height: '',
                listData: [
                    {
                        time: '2019-08-20',
                        arr: [
                            [
                                {
                                    message2:
                                        '1额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                                },
                                {
                                    message2:
                                        '2额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                                },
                                {
                                    message2:
                                        '3额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                                }
                            ],
                            [
                                {
                                    message2:
                                        '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                                }
                            ]
                        ]
                    }
                    // {time:'2019-08-19',size: 7, num: 3},
                    // {time:'2019-08-18',size: 5, num: 2},
                    // {time:'2019-08-17',size: 9, num: 3},//数据传过来。加一个参数 numbSize把总数/3向上取整数（方便遍历数据）
                    // {time:'2019-08-16',size: 11, num: 4},
                ],
                listData2: [
                    {time: '2019-08-20', message2: ''},
                    {
                        message2: [
                            '如果你这样做出警告',
                            '2额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 pr味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                        ]
                    },
                    {
                        message2: [
                            '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                        ]
                    },
                    {time: '2019-08-20', message2: ''},
                    {
                        message2: [
                            '1额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '2额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 pr味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                        ]
                    },
                    {
                        message2: [
                            '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                        ]
                    },
                    {time: '2019-08-20', message2: ''},
                    {
                        message2: [
                            '1额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '2额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 pr味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                        ]
                    },
                    {
                        message2: [
                            '4额外的，每告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告',
                            '4额外的，每次父级组件发生更新时，子组件中所有的 prop 都将会刷新为最新的值。这意味着你不应该在一个子组件内部改变 prop。如果你这样做了，Vue 会在浏览器的控制台中发出警告'
                        ]
                    }
                ]
            }
        },
        created() {
            //中文30个字隐藏否则60个隐藏
            try {
                if (localStorage.getItem('language') == 'CN') {
                    this.fontNum = 36
                }
            } catch {
            }
        },
        mounted() {
            this.height = window.document.body.clientHeight - 252 + 'px'
            console.log(this.height, window.document.body.clientHeight)
        },
        methods: {
            tabFn(index) {
                this.tabIndex = index
                console.log(index)
            },
            delDayFn(id) {
                console.log(id)
                this.progressFn()
                this.success()
                this.error()
            },
            progressFn() {
                const hide = this.$message.loading('Action in progress..', 0)
                setTimeout(hide, 2500)
            },
            success() {
                this.$message.success('This is a message of success')
            },
            error() {
                this.$message.error('This is a message of error')
            },
            //分页
            onChange(pageNumber) {
                console.log('Page: ', pageNumber)
            },
            //点击列表事件
            clickFn(id) {
                console.log(id)
            }
        }
    }
</script>

<style lang="less" scoped>
    .newList {
        position: relative;
        padding: 24px 16px;
        background: #fff;
        min-height: 800px;
    }

    .ant-btn {
        border-radius: 20px;
    }

    .drak {
        color: #999999;
    }

    .box {
        display: flex;
        align-items: flex-start;
        & > div {
            width: 32%;
            display: inline-block;
        }
        & > div:nth-of-type(2) {
            margin: 0px 2%;
        }
    }

    .flexBox {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        & > div {
            width: 32%;
        }
    }

    //  .ant-timeline-item-last .ant-timeline-item-tail{display: block;}
    .line {
        border-bottom: 1px solid #e0e1e2;
    }

    .ContentBox {
        box-shadow: 0px 2px 8px 0px rgba(117, 113, 249, 0.13);
        padding: 10px 16px;
        // margin: 10px 0px;
        border-radius: 2px;
        font-size: 14px;
        color: #666666;
        cursor: pointer;
        .title {
            color: #333333;
        }
        span {
            margin-top: 8px;
            display: inline-block;
        }
    }

    .border-left-bg-cx {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 4px;
        height: 100%;
    }

    .default-color {
        background-color: #e0e1e2;
    }

    /*.posTop{display: flex;align-items: center;}*/
</style>
